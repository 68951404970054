import React from "react"
import {
  Card,
  CardContent,
  Button,
  Grid,
} from "@material-ui/core"
import { theme } from "../../common/site-variables"
import pixelBanner from '../../static-images/pixel_banner.png'

// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */
import { jsx, css } from "@emotion/core"

interface PropTypes {
  isMobile: boolean
}

const ValueForMoney = (props: PropTypes) => {
  const { isMobile } = props

  const styles = css(`
  display: flex;
  justify-content: center; 
  padding-top: 5%;

  .valueCard {
    position: relative;
    width: 90%;
    min-height: 80vh;
    overflow: visible;

    p, h1 {
      padding: 0 5%;
    }

    .valuesFooter {
        position: absolute;
        left: 0;
        bottom: 0;
        display: flex;
        width: 100%;
        height: ${isMobile ? 60 : 50}%;

        .leftSide, .rightSide {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
        }

        .rightSide {
          position: relative;
          top: ${isMobile ? 0 : 27}%;
          justify-content: center;
          height: ${isMobile ? 30 : 73}%;
          background-color: ${isMobile ? theme.cs_yellow : 'transparent'};

          .bookConsultBtn {
            color: white;
            height: ${isMobile ? 80 : 30}%;
            background-color: ${theme.cs_blue};
          }

        }

        .leftSide {
          height: ${isMobile ? 70 : 100}%;
          background: url(${pixelBanner});
          background-size: ${isMobile ? '100% 80%' : '80% 100%'};
          background-position: ${isMobile ? 'top' : 'center'};
          background-repeat: no-repeat;

          .text {
            width: 100%;
            background-color: ${theme.cs_yellow};
            padding: 0 8%;

            h3, p {
              margin-top: 3%;
              text-align: center;
            }
          }
        }
      }

    svg {
      position: absolute;
      top: 6vh;
      left: -13px;
      width: calc(100% + 13.5px);
      height: 914.184;
      pointer-events: none;

    }
  `)

  return (
    <section css={styles}>
      <Card className="valueCard">
        <CardContent>
          <h1>Value for your money</h1>
          <p>
            GC Technology aims to not only give value for money but create
            value-added from your old or worn computer, tablet or smartphone.
          </p>
          

          <Grid container className="valuesFooter">
            <Grid item lg={6} sm={12} xs={12} className="leftSide">
              <div className="text">
                <h3>Let us work for you</h3>
                <p>Call us and we will arrange parking especially for you.</p>
              </div>
            </Grid>

            <Grid item lg={6} sm={12} xs={12} className="rightSide">
              <Button variant="contained" className="bookConsultBtn">
                Book a consultation
              </Button>
            </Grid>

          </Grid>
        </CardContent>

        { !isMobile && 
        <svg viewBox="0 0 1703.782 914.184">
          <circle
            id="Ellipse_1"
            data-name="Ellipse 1"
            cx="13.5"
            cy="13.5"
            r="13.5"
            fill="#ffcf00"
          />
          <path
            id="Path_1"
            data-name="Path 1"
            d="M72.218,795.264v689.583H1757.282V1217.759H971.094v496.689"
            transform="translate(-56.718 -800.264)"
            fill="none"
            stroke="#ffcf00"
            strokeWidth="4"
          />
        </svg>
        }
      </Card>
    </section>
  )
}

export default ValueForMoney
