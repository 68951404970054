import React from "react"
import {
  Card,
  CardContent,
  Button,
  Grid,
  CardMedia,
} from "@material-ui/core"
import { theme } from "../../common/site-variables"
import ArrowRightAlt from "@material-ui/icons/ArrowRightAlt"

import { services_data } from '../../common/hard-coded-data'

// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */
import { jsx, css } from "@emotion/core"

interface PropTypes {
  isMobile: boolean
}

const ServicesSection = (props: PropTypes) => {
  const { isMobile } = props

  const styles = css(`
    padding: 5%;
    width: 100%;
    margin-top: 12%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .services {
      display: flex;
      flex-wrap: wrap;
      flex-shrink: 0;

      .serviceCardWrapper {
        padding: 3%;

        .serviceCard {
          height: 70vh;
          width: 100%;
          position: relative;

          .media {
            height: 0;
            padding-top: 56.25%;
          }

          .action {
            position: absolute;
            bottom: 0;
            display: flex;
            justify-content: flex-end;
            width: 100%;
            height: 10%;

            button {
              width: 50%;
              height: 100%;
              background-color: ${theme.cs_yellow};
              border-radius: 25% 0% 0% 0% / 80% 0% 0% 0%;
              color: white;
              font-size: 7px;
            }
          }
        }
      }
    }
  `)

  return (
    <section css={styles}>
      <h1>Services</h1>

      <Grid container className="services">
        {services_data.map((service) => (
          <Grid item lg={4} md={6} sm={12} xs={12} className="serviceCardWrapper" key={service.img}>
            <Card className="serviceCard">
              <CardMedia className="media" image={service.img} />
              <CardContent>
                <h3>{service.title}</h3>
                <p>{service.body}</p>
              </CardContent>
              <div className="action">
                <Button>
                  <ArrowRightAlt fontSize="large" />
                </Button>
              </div>
            </Card>
          </Grid>
        ))}
      </Grid>
    </section>
  )
}

export default ServicesSection
