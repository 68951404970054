import React, { useState } from "react"

/**@jsx jsx */
import { jsx, css } from "@emotion/core"
import { theme } from '../../common/site-variables'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Product } from '../../data/product-model'
import { getProducts } from '../../services/product-service'
import CartContext from '../../data/cart-context'
import ProductCard from '../productCard'
import { useSprings } from 'react-spring'

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  }
};


interface PropTypes {
  isMobile: boolean;
}

const ProductsWidget = (props: PropTypes) => {
  const { isMobile } = props;

  const [products, setProducts] = useState<Product[]>([]);
  const [prodIconSprings, setProdIconSprings] = useSprings(products.length , i => ({right: '-64px'}));
  const [activeCard, setActiveCard] = React.useState(-1);

  React.useEffect(() => {

    getProducts(null, null, null, true).then(
      res => {
        console.log(res.data)
        setProducts(res.data as Product[]);
      }, errors => {
        console.log(errors);
      }
    );

  }, [])

  const styles = css(`
    padding: 5%;
    width: 100%;
    margin-top: 12%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .content {
      width: 100%;

    }

    .carousel-container {
      width: 100%;

      .carousel-item {
        min-width: 20vw;
        margin: 2%;
        min-height: 35vh;

        .productCard {
          position: relative;
          min-height: 50vh;
          padding-bottom: 10vh;

          .media {
            height: 150px;
          }

          .actions {
            position: absolute;
            bottom: 0;
            height: 10vh;
            width: 100%;
            justify-content: space-between;

            .addCartBtn {
              background-color: ${theme.cs_dark_blue};
              color: white;

              &:hover {
                background-color: ${theme.cs_yellow};
                color: ${theme.cs_dark_blue};
              }
            }

            .priceDiv {
              color: ${theme.cs_dark_blue};
              font-size: 1.5rem;
            }
          }
        } 
      }
    }

  `);

  const cartState = React.useContext(CartContext);
    

  const handleAddCartClick = (product: Product) => () => {
    cartState.updateCartProducts(product, true)
  }

  const handleCardMouseEnter = (index: number) => () => {
    setActiveCard(index);
    //@ts-ignore
    setProdIconSprings((i: number) => {
      if (index !== i) return
        
      return {right: '0px'}
    });
  }

  const handleCardMouseLeave = (index: number) => () => {
    setActiveCard(-1);
    //@ts-ignore
    setProdIconSprings((i: number) => {
      if (index !== i) return
          
      return {right: '-64px'}
    });
  }

  return (
    <section css={styles}>
      <h1>Featured Products</h1>

      <div className="content">
        { products?.length > 0 ?
          <Carousel
          swipeable
          draggable
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          autoPlay
          autoPlaySpeed={5000}
          keyBoardControl={true}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item"
        >
          {
            prodIconSprings.map((props, index) => (
              <ProductCard 
                key={products[index].id}
                product={products[index]} 
                onMouseEnter={handleCardMouseEnter(index)} 
                onMouseLeave={handleCardMouseLeave(index)}
                detProps={props} 
                active={activeCard === index}
                height={`${isMobile ? 90 : 55}vh`}
                descCharCount={80}
              />
            ))
          }    
        </Carousel>
        :
        <h5 style={{textAlign: 'center'}}>No products in this category</h5>
        } 
      </div>
    </section>
  )
}

export default ProductsWidget
