import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

interface PropTypes {
  logoType?: 'fixed' | 'fixedLight' | 'fluid' | 'fluidLight';
}


const ProgressiveLogo = (props: PropTypes) => {
  const data = useStaticQuery(graphql`
    query {
      fixedLogo: file(relativePath: {eq: "progressive_b&w.png"}) {
        childImageSharp {
          fixed(height: 70, width: 125) {
            ...GatsbyImageSharpFixed
          }
        }
      },

      logo: file(relativePath: { eq: "progressive_b&w.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  let fixed = false;
  let imgData;

  switch (props.logoType) {
    case 'fixed' : 
      fixed = true;
      imgData =  data.fixedLogo.childImageSharp.fixed;
      break;
    default : imgData = data.logo.childImageSharp.fluid; 
  }

  return fixed ?
    <Img fixed={imgData} /> :
    <Img fluid={imgData} />

}

export default ProgressiveLogo
