import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import ProductsWidget from "../components/home-components/products-widget"
import ResponsiveView from "../responsive/view"
import ValueForMoney from '../components/home-components/valueMoney'
import AboutSection from '../components/home-components/about'
import ServicesSection from '../components/home-components/services'
import FloatingCart from '../components/floating-cart'

const IndexPage = () => {

  return (
    <Layout>
      <SEO title="Home" />

      <ResponsiveView render={isMobile => (
        <FloatingCart isMobile={isMobile} />
      )} />

      <ResponsiveView render={(isMobile) => (
        <Hero 
          isMobile={isMobile}
          page="home" 
          title="IT Solutions for Homes & Businesses"
          body="Concentrate on your core business and not IT issues. We provide
          Computer repairs, Digital Camera Systems installation and support, as
          well as, Enterprise Mobility solutions."
        />
      )} />

      <ResponsiveView render={isMobile => (
        <ValueForMoney isMobile={isMobile} />
      )} />

      <ResponsiveView render={isMobile => (
        <AboutSection isMobile={isMobile} />
      )} />

      <ResponsiveView render={isMobile => (
        <ServicesSection isMobile={isMobile} />
      )} />

      <ResponsiveView render={isMobile => (
        <ProductsWidget isMobile={isMobile} />
      )} />

    </Layout>
  )
}

export default IndexPage