import React from "react"
import {
  Button,
  Grid,
} from "@material-ui/core"
import Logo from "../image-components/logo"
import {
  theme,
} from "../../common/site-variables"
import LascoLogo from "../../components/image-components/lasco-logo"
import ProgressiveLogo from "../../components/image-components/progressive-logo"
import JPSLogo from "../../components/image-components/jps-logo"
import ShoppersFairLogo from "../../components/image-components/shoppersFair-logo"
import bg from '../../static-images/gradient_backdrop_2.png'

// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { navigate } from 'gatsby'

interface PropTypes {
  isMobile: boolean
}

const AboutSection = (props: PropTypes) => {
  const { isMobile } = props

  const styles = css(`
    position: relative;
    height: ${isMobile ? 'auto' : '80vh'};
    min-height: 80vh;
    width: 100%;
    margin-top: 12%;
    border-top: solid medium ${theme.cs_yellow};
    color: white;

    .bgImage {
      position: absolute;
      top: 0; left: 0;
      width: 100%;
      height: 100%;
      background: url(${bg});
      background-size: 100% 100%;
      z-index: 2;
    }

    .leftSide, .rightSide {
      position: relative;
      z-index: 3;
      height: 77.4%;
    }

    .leftSide {
      padding: ${isMobile ? '5% 5% 5% 12%' : '2% 5%'};
      border-bottom: solid medium ${theme.cs_yellow};

      button {
        width: 50%;
        background-color: ${theme.cs_yellow};
      }
                
      .logoCont {
        width: 40%;
      }

      h1 {
        .important {
          color: ${theme.cs_yellow};
        }
      }

    }

    .rightSide {
      padding: ${isMobile ? '5% 10%' : '2% 5%'};
      background-color: ${isMobile ? theme.cs_dark_blue : 'transparent'};

      .clientsDiv {
        width: ${isMobile ? 100 : 70}%;
        
        .logoCont {
          padding: 5%;
        }
      }
    }

    svg {
      position: absolute;
      left: 50%;
      top: 70%;
      z-index: 1;
      transform: translateX(-50%);
    }

  `)

  return (
    <Grid component="section" container css={styles} className="flex">
      <span className="bgImage"></span>

      <Grid item lg={6} md={12} sm={12} xs={12} className="leftSide flexCol">
        <div className="logoCont">
          <Logo logoType="fluidLight" />
        </div>
        <h1>
          is <span className="important">for Everyone</span>
        </h1>
        <p>
          Serving you since 2013. We started off operating mostly from home
          selling mobile computers and Motorola handheld devices for a few
          years. Then, we started doing computer repairs, working for the
          average person as well as some big companies.
        </p>
        <Button variant="contained" onClick={() => navigate('/company')}>More about us</Button>
      </Grid>

      <Grid item lg={6} md={12} sm={12} xs={12} className="rightSide">
        <h4>Some of our clients</h4>

        <Grid container className="clientsDiv">

          <Grid item xs={6} className="logoCont">
            <ProgressiveLogo />
          </Grid>
          
          <Grid item xs={6} className="logoCont">
            <JPSLogo />
          </Grid>

          <Grid item xs={6} className="logoCont">
            <ShoppersFairLogo />
          </Grid>

          <Grid item xs={6} className="logoCont">
            <LascoLogo />
          </Grid>

        </Grid>
      </Grid>

      {!isMobile && 
        <svg width="27" height="377.001" viewBox="0 0 27 377.001">
          <g
            id="Group_16"
            data-name="Group 16"
            transform="translate(-947 -2728.999)"
          >
            <path
              id="Path_2"
              data-name="Path 2"
              d="M1010.461,2127l-.16,362.727"
              transform="translate(-50 602)"
              fill="none"
              stroke="#ffcf00"
              strokeWidth="4"
            />
            <circle
              id="Ellipse_2"
              data-name="Ellipse 2"
              cx="13.5"
              cy="13.5"
              r="13.5"
              transform="translate(947 3079)"
              fill="#ffcf00"
            />
          </g>
        </svg>
      }
    </Grid>
  )
}

export default AboutSection
